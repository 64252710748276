import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase/firebaseConfig'; // Firebase initialized separately
import { signInWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';

// Create an AuthContext
const AuthContext = createContext();

// Create a custom hook to use the AuthContext
export const useAuth = () => {
    return useContext(AuthContext);
};

// AuthProvider component that will wrap the app
export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    // Sign in function
    const signIn = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password);
    };

    // Sign out function
    const logout = () => {
        return signOut(auth);
    };

    // Effect to track the authentication state
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            setLoading(false); // Loading is done once we know the user's auth state
        });

        // Cleanup the listener on unmount
        return unsubscribe;
    }, []);

    // Values to be provided via context
    const value = {
        currentUser,
        signIn,
        logout,
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children} {/* Ensure that the app isn't rendered until loading is complete */}
        </AuthContext.Provider>
    );
};
