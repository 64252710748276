import React, { useCallback, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';

// Load Stripe outside of the component's render to avoid recreating the Stripe object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const StripeCheckoutForm = ({ answers }) => {
    const [error, setError] = useState(null); // State to manage error messages

    const fetchClientSecret = useCallback(async () => {
        // Check if answers are provided and not empty
        if (!answers || Object.keys(answers).length === 0) {
            setError('Please provide answers before proceeding.');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ answers }), // Send answers in the request body
            });

            if (!response.ok) {
                throw new Error('Failed to create checkout session');
            }

            const data = await response.json();
            return data.clientSecret;

        } catch (error) {
            setError(error.message);
            console.error(error);
        }
    }, [answers]);

    const options = { fetchClientSecret };

    return (
        <div id="checkout">
            {error && <div className="error">{error}</div>} {/* Display error message if there's an error */}
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </div>
    );
};

export default StripeCheckoutForm;
