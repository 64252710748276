import React, { useState, useEffect, memo } from 'react';
import { motion } from 'framer-motion';
import image17 from '../../assets/media/17.png';
import image18 from '../../assets/media/18.GIF';
import image19 from '../../assets/media/19.GIF';
import image20 from '../../assets/media/20.gif';


import StripeCheckoutForm from './StripeCheckoutForm';

// Countdown Timer Component
const CountdownTimer = () => {
    const [timeRemaining, setTimeRemaining] = useState(15 * 60); // 15 minutes in seconds

    useEffect(() => {
        if (timeRemaining <= 0) return;

        const timer = setInterval(() => {
            setTimeRemaining(prevTime => prevTime - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, [timeRemaining]);

    const formatTime = (time) => {
        const minutes = String(Math.floor(time / 60)).padStart(2, '0');
        const seconds = String(time % 60).padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    return (
        <div className='bg-[#242424] rounded-xl flex flex-col items-center gap-1 px-12 py-6'>
            <p className='text-white font-light text-sm leading-loose tracking-wide text-center bg-transparent'>
                Your personalized treatment has been saved for the next 15 minutes
            </p>
            <b className='text-white font-medium text-sm leading-loose tracking-wide text-center bg-transparent'>
                Time Remaining:
            </b>
            <p className='text-white text-3xl leading-loose tracking-wide text-center bg-transparent'>
                {formatTime(timeRemaining)}
            </p>
        </div>
    );
};

// Review Component
const Review = memo(({ imageSrc, customerName, reviewText }) => (
    <div className="w-64 rounded shrink-0 flex flex-col">
        <img src={imageSrc} alt={`${customerName}'s review`} className="object-contain w-auto h-[450px]"
            onLoad={(e) => {
                e.target.style.filter = 'none';
            }}
            style={{
                filter: "blur(8px)",
                transition: "filter 1.6s ease-in-out" // Adding height transition
            }} />
        <label className="pt-4 font-normal text-white">{customerName}</label>
        <p className="pt-4 text-white font-light">{reviewText}</p>
    </div>
));

// Reviews Section Component
const ReviewsSection = () => {
    const reviews = [
        {
            imageSrc: image18,
            customerName: 'Ankur',
            reviewText: '“I went from no hair on the top to all this. My favorite exercise is the scalp pinching.“',
        },
        {
            imageSrc: image19,
            customerName: 'Michael',
            reviewText: '“Hair on the sides and the top has regrown, scalp health has increased. This actually worked for me.” ',
        },
        {
            imageSrc: image20,
            customerName: 'Collin',
            reviewText: '“Hair has grown a lot and I see baby hairs. Proud to be a part of the KESHAH Family.“',
        },
    ];

    return (
        <div className='w-full overflow-x-auto hide-scrollbar'>
            <div className='flex gap-4'>
                {reviews.map((review, index) => (
                    <Review key={index} {...review} />
                ))}
            </div>
        </div>
    );
};

// Main Quiz Completed Component
const QuizCompleted = ({ answers, onPrevious }) => {
    const animationDelay = 0.5; // Base delay for consistent animation timing

    useEffect(() => {
        // Scroll to top whenever the data (i.e., screen) changes
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='w-full sm:w-[420px] mx-auto min-h-screen'>
            <div className='w-full p-4'>
                {/* Header */}
                <motion.div
                    className='mt-6 w-full h-fit flex items-center justify-between'
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ ease: "easeInOut", duration: 0.5, delay: animationDelay }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5 text-white cursor-pointer" onClick={onPrevious}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                    </svg>
                    <img src="https://keshah.com/wp-content/uploads/2024/09/KESHAHWhiteLogoPNG-2.png" className="h-[24px] w-auto" alt="Keshah Logo" />
                    <div className='appearance-none opacity-0'>as</div>
                </motion.div>

                <motion.h1 className='mt-6 text-white font-light text-4xl'
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ ease: "easeInOut", duration: 1.5, delay: animationDelay + 0.3 }}>
                    Try your first month of natural hair regrowth treatment, <b>50% OFF.</b>
                </motion.h1>

                <motion.p className='mt-6 text-white font-light text-sm leading-loose tracking-wide'
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ ease: "easeInOut", duration: 1.5, delay: animationDelay + 0.6 }}>
                    Our two 'super-oils,' made from 12 natural oils and extracts sourced from Japan and India, combine with mechanotherapy through our app to stop and reverse your hair loss.
                </motion.p>

                <motion.p className='mt-6 text-white font-light text-sm leading-loose tracking-wide'
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ ease: "easeInOut", duration: 1.5, delay: animationDelay + 0.8 }}>
                    Reverse your hair loss from home—no drugs, no pills, no side-effects.
                </motion.p>

                <motion.img src={image17} alt="Final Screen" className='mt-6'
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ ease: "easeInOut", duration: 1.5, delay: animationDelay + 1 }}
                    onLoad={(e) => {
                        e.target.style.filter = 'none';
                    }}
                    style={{
                        filter: "blur(8px)",
                        transition: "filter 1.6s ease-in-out" // Adding height transition
                    }} />

                <motion.div className='mt-6 flex flex-col gap-8'
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ ease: "easeInOut", duration: 1, delay: animationDelay + 1.2 }}>
                    {[
                        { name: 'Vriddhi Oil', price: 29, description: '30 day supply per bottle Ships every 30 days' },
                        { name: 'Ojus Oil', price: 29, description: '30 day supply per bottle Ships every 30 days' },
                        { name: 'Mechanotherapy Regrowth Sessions', price: 39, description: 'Follow along with your video mechanotherapy sessions for 20 minutes each day' },
                        { name: 'Live Chat Support', price: 0, description: 'Your personal companion will be by your side throughout the entire treatment', originalPrice: 29 },
                        { name: '30-Day Video Check in Call', price: 0, description: 'A video check-in call with a KESHAH hair expert to ensure you\'re on the right track', originalPrice: 49 },
                        { name: 'Regrowth Tracking', price: 0, description: 'Track your daily progress, take before-and-after photos, and get weekly optimizations through surveys to monitor your regrowth over time.', originalPrice: 19 },
                    ].map((item, index) => (
                        <motion.div
                            className='flex justify-between gap-12'
                            key={index}
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ ease: "easeInOut", duration: 1, delay: animationDelay + 1.5 + index * 0.2 }} // Staggered delay for each item
                        >
                            <div>
                                <span className='text-white font-medium text-sm'>{item.name}</span>
                                <p className='mt-1 text-gray-400 text-[10px] tracking-wider tracking- leading-relaxed'>{item.description}</p>
                            </div>
                            <div className='text-white font-medium text-sm'>
                                {item.price === 0 ? (
                                    <span className='inline-flex gap-2'>FREE <strike className='text-gray-600'>${item.originalPrice}</strike></span>
                                ) : (
                                    `$${item.price}`
                                )}
                            </div>
                        </motion.div>
                    ))}
                </motion.div>

                <motion.div className='mt-6 w-full h-[0.30px] bg-gray-400'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: "easeInOut", duration: 1, delay: animationDelay + 1.8 }}>
                </motion.div>

                <motion.div className='mt-6 px-6 py-6 border rounded-2xl flex flex-col gap-3 justify-center'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: "easeInOut", duration: 1, delay: animationDelay + 2 }}>
                    {[
                        { label: 'Subtotal', value: 97, originalValue: 194 },
                        { label: 'Promotion (HALFOFFFIRSTMONTH)', value: -49 },
                        { label: 'Delivery Fee', value: 0 },
                        { label: 'Total', value: 48, originalValue: 194 },
                    ].map(({ label, value, originalValue }, index) => (
                        <motion.div
                            className='flex items-center justify-between'
                            key={index}
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ ease: "easeInOut", duration: 1, delay: animationDelay + 2.2 + index * 0.2 }} // Staggered delay for each summary item
                        >
                            <p className='text-white text-sm font-extralight'>{label}</p>
                            <p className='text-white text-sm font-extralight inline-flex gap-1'>
                                <b className={`${index === 1 ? 'text-green-600' : ''}`}>{value < 0 ? '-' : ''}${Math.abs(value)}</b>
                                {originalValue && <strike className='text-gray-400'>${originalValue}</strike>}
                            </p>
                        </motion.div>
                    ))}
                </motion.div>

                <motion.p className='mt-6 text-white text-sm font-extralight text-center'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: "easeInOut", duration: 1, delay: animationDelay + 2.8 }}>
                    Subscription automatically renews at $97/month next month. No commitment. Cancel anytime.
                </motion.p>

                <motion.div className='mt-6'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: "easeInOut", duration: 1, delay: animationDelay + 3 }}>
                    <StripeCheckoutForm answers={answers} />
                </motion.div>

                <motion.div className='mt-6 w-fit mx-auto'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: "easeInOut", duration: 1, delay: animationDelay + 3.2 }}>
                    <p className='text-white text-sm font-extralight'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5 inline mr-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        No commitment. Cancel anytime
                    </p>
                    <p className='mt-2 text-white text-sm font-extralight'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5 inline mr-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        Backed by money back guarantee
                    </p>
                </motion.div>

                <motion.div className='mt-6'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: "easeInOut", duration: 1, delay: animationDelay + 3.5 }}>
                    <CountdownTimer />
                </motion.div>

                <motion.div className='mt-6'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: "easeInOut", duration: 1, delay: animationDelay + 3.8 }}>
                    <ReviewsSection />
                </motion.div>

                <motion.div className='mt-6 bg-[#242424] rounded-xl flex flex-col gap-1 p-11'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: "easeInOut", duration: 1, delay: animationDelay + 4 }}>
                    <p className='text-white font-semibold text-base leading-loose tracking-wide bg-transparent'>
                        100% Money Back Guarantee.
                    </p>
                    <p className='mt-2 text-white font-light text-[14px] tracking-normal bg-transparent'>
                        Enjoy extra peace of mind. You can claim a full refund within 30 days if you’ve completed at least 80% of the sessions and still feel KESHAH isn’t right for you. Just email us at contact@keshah.com.
                    </p>
                </motion.div>

                <motion.div className='w-full bg-black p-4'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: "easeInOut", duration: 1, delay: animationDelay + 4.5 }}>
                    {/* Footer */}
                    <div className='mt-6 w-full'>
                        <div className='w-full h-[0.30px] bg-gray-400'></div>
                        <div className='pt-4 text-gray-400 text-xs font-extralight'>
                            *This is a limited-time offer and is subject to change at any moment based on availability.
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default QuizCompleted;
