import React,{useEffect} from 'react';
import { motion } from 'framer-motion';
import finalScreenPhotoGrid01 from '../../assets/media/Daily Routine/finalScreenPhotoGrid01.png';
import finalScreenPhotoGrid02 from '../../assets/media/Daily Routine/finalScreenPhotoGrid02.png';
import finalScreenPhotoGrid03 from '../../assets/media/Daily Routine/finalScreenPhotoGrid03.png';
import finalScreenPhotoGrid04 from '../../assets/media/Daily Routine/finalScreenPhotoGrid04.png';
import finalScreenPhotoGrid05 from '../../assets/media/Daily Routine/finalScreenPhotoGrid05.png';
import finalScreenPhotoGrid06 from '../../assets/media/Daily Routine/finalScreenPhotoGrid06.png';
import finalScreenPhotoGrid07 from '../../assets/media/Daily Routine/finalScreenPhotoGrid07.png';
import finalScreenPhotoGrid08 from '../../assets/media/Daily Routine/finalScreenPhotoGrid08.png';
import finalScreenPhotoGrid09 from '../../assets/media/Daily Routine/finalScreenPhotoGrid09.png';
import finalScreenPhotoGrid10 from '../../assets/media/Daily Routine/finalScreenPhotoGrid10.png';

const images = [
    finalScreenPhotoGrid01,
    finalScreenPhotoGrid02,
    finalScreenPhotoGrid03,
    finalScreenPhotoGrid04,
    finalScreenPhotoGrid05,
    finalScreenPhotoGrid06,
    finalScreenPhotoGrid07,
    finalScreenPhotoGrid08,
    finalScreenPhotoGrid09,
    finalScreenPhotoGrid10,
    finalScreenPhotoGrid01,
    finalScreenPhotoGrid02,
    finalScreenPhotoGrid03,
    finalScreenPhotoGrid04,
    finalScreenPhotoGrid05,
    finalScreenPhotoGrid06,
    finalScreenPhotoGrid07,
    finalScreenPhotoGrid08,
    finalScreenPhotoGrid09,
    finalScreenPhotoGrid10,
    finalScreenPhotoGrid01,
    finalScreenPhotoGrid02,
    finalScreenPhotoGrid03,
    finalScreenPhotoGrid04,
    finalScreenPhotoGrid05,
    finalScreenPhotoGrid06,
    finalScreenPhotoGrid07,
    finalScreenPhotoGrid08,
    finalScreenPhotoGrid09,
    finalScreenPhotoGrid10,
];

const WalkthroughScreen = ({ onNext, onPrevious }) => {
    const animationDelay = 0.5; // Base delay for consistent animation timing

    useEffect(() => {
        // Scroll to top whenever the data (i.e., screen) changes
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='w-full sm:w-[420px] mx-auto min-h-screen'>
            <div className='w-full p-4'>
                {/* Header */}
                <motion.div
                    className='mt-6 w-full h-fit flex items-center justify-between'
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ ease: "easeInOut", duration: 0.5, delay: animationDelay }}>
                    <svg onClick={onPrevious} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5 text-white cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                    </svg>
                    <img src="https://keshah.com/wp-content/uploads/2024/09/KESHAHWhiteLogoPNG-2.png" className="h-[24px] w-auto" alt="Keshah Logo" />
                    <div className='opacity-0'>ass</div>
                </motion.div>

                {/* Title */}
                <motion.h1
                    className='mt-6 text-white font-light text-3xl'
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ ease: "easeInOut", duration: 1, delay: animationDelay + 0.3 }}>
                    Join thousands of men <i>actually</i> regrowing their hair—completely drug and side-effect free.
                </motion.h1>

                {/* Stats */}
                <motion.div
                    className='mt-6 flex gap-8'
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ ease: "easeInOut", duration: 1, delay: animationDelay + 0.6 }}>
                    <div className="px-6 py-6 border rounded-2xl flex flex-col gap-1 justify-center">
                        <span className='text-[#369033] text-xl'>88%<span className='text-white'>*</span></span>
                        <p className='text-white text-sm font-extralight'>See regrowth in 120 days.</p>
                    </div>
                    <div className="px-6 py-6 border rounded-2xl flex flex-col gap-1 justify-center">
                        <span className='text-[#369033] text-xl'>0%<span className='text-white'>*</span></span>
                        <p className='text-white text-sm font-extralight'>Reported side-effect risk.</p>
                    </div>
                </motion.div>

                {/* Infinite Scroll Image Row */}
                <motion.div
                    className='overflow-hidden mt-6'
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ ease: "easeInOut", duration: 1, delay: animationDelay + 0.9 }}>
                    <motion.div
                        className='flex w-full gap-4'
                        initial={{ x: 0 }}
                        animate={{ x: -images.length * 100 }}
                        transition={{ ease: "linear", duration: images.length * 1.2, repeat: Infinity }}
                    >
                        {[...images, ...images].map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={`Routine step ${index + 1}`}
                                className='w-auto h-[250px] rounded-xl shrink-0'
                                onLoad={(e) => {
                                    e.target.style.filter = 'none';
                                }}
                                style={{
                                    filter: "blur(8px)",
                                    transition: "filter 1.6s ease-in-out" // Adding height transition
                                }}
                            />
                        ))}
                    </motion.div>
                </motion.div>

                {/* Footer with Sequential Animation */}
                <div className='mt-6 w-full'>
                    <motion.div
                        initial={{ opacity: 0, y: 10 }} // Initial animation state for the divider
                        animate={{ opacity: 1, y: 0 }} // Final animation state for the divider
                        transition={{ ease: "easeInOut", duration: 0.5, delay: animationDelay + 1.2 }} // Animation timing and delay
                    >
                        <div className='w-full h-[0.30px] bg-gray-400'></div>
                    </motion.div>

                    <motion.div
                        initial={{ opacity: 0, y: 10 }} // Initial animation state for the text
                        animate={{ opacity: 1, y: 0 }} // Final animation state for the text
                        transition={{ ease: "easeInOut", duration: 0.5, delay: animationDelay + 1.5 }} // Sequential delay for text
                    >
                        <div className='pt-4 text-gray-400 text-xs font-extralight'>
                            *This data is regularly updated to ensure accuracy, reflecting the latest results collected through the KESHAH App.
                        </div>
                    </motion.div>
                </div>
            </div>

            <motion.div className='w-full p-4'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ ease: "easeInOut", duration: 1, delay: animationDelay + 1.8 }}>
                <button onClick={onNext} className={`text-black bg-white flex h-12 w-full items-center justify-center rounded-xl text-base font-semibold lg:text-base`}>
                    <span className="tracking-tight bg-transparent">Next</span>
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-2">
                        <path d="M6.1584 3.13508C6.35985 2.94621 6.67627 2.95642 6.86514 3.15788L10.6151 7.15788C10.7954 7.3502 10.7954 7.64949 10.6151 7.84182L6.86514 11.8418C6.67627 12.0433 6.35985 12.0535 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1579L9.565 7.49985L6.1356 3.84182C5.94673 3.64036 5.95694 3.32394 6.1584 3.13508Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
                    </svg>
                </button>
            </motion.div>
        </div>
    );
};

export default WalkthroughScreen;
