// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDkpZmpH9NBZ8ax9TMJSSsPddQ9SVxbO5w",
    authDomain: "keshah-app.firebaseapp.com",
    databaseURL: "https://keshah-app-default-rtdb.firebaseio.com",
    projectId: "keshah-app",
    storageBucket: "keshah-app.appspot.com",
    messagingSenderId: "33815207242",
    appId: "1:33815207242:web:af67261883aff7e182b5b3",
    measurementId: "G-HK5MKVTRPS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
