import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import ScrollToTop from './ScrollToTop';
import QuizPage from './pages/user/QuizPage';
import Admin from './pages/admin/Admin';
import AdminDashboard from './pages/admin/AdminDashboard';
import ThankYou from './pages/user/ThankYou';

export default function App() {
  return (
    <Router basename="/">
      <ScrollToTop />
      <AuthProvider>
        <Routes>
          <Route path="/" element={<QuizPage />} />
          <Route path="/thank-you/:sessionID?" element={<ThankYou />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          {/* Other routes */}
        </Routes>
      </AuthProvider>
    </Router>

  )
}

