import React, { useEffect, useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { DashboardIcon } from '../../assets/icons/Icons';
import KeshahQuiz from '../../components/admin/KeshahQuiz';

const dashboardViews = [
  { icon: <DashboardIcon />, label: 'Keshah Quiz', component: <KeshahQuiz /> },
];

export default function AdminDashboard() {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [sidebarVisible, setSidebarVisibility] = useState(false);
  const [activeDashboardView, setActiveDashboardView] = useState(dashboardViews[0]);

  useEffect(() => {
    if (!currentUser) {
      navigate('/admin');
    }
  }, [currentUser, navigate]);

  const handleViewSelect = useCallback((view) => {
    setActiveDashboardView(view);
    if (window.innerWidth <= 640) setSidebarVisibility(false);
  }, []);

  const handleLogout = useCallback(async () => {
    if (window.confirm('Do you want to sign out?')) {
      await logout();
    }
  }, [logout]);

  const { displayName, email, emailVerified } = currentUser || {};

  return (
    <div className='w-full h-screen overflow-hidden flex relative box-border'>
      {/* Sidebar */}
      <div className={`shrink-0 h-full bg-[#141414] p-4 flex flex-col gap-8 w-4/6 absolute z-30 sm:relative transform -translate-x-full sm:transform-none sm:w-[270px] bg-primary-900 rounded-r-3xl shadow-xl border-r-[1px] border-r-[#272727b5] overflow-hidden transition-all duration-500 ease-in-out ${sidebarVisible ? 'transform-none' : ''}`}>
        <motion.div className='text-xl cursor-pointer w-fit' onClick={() => navigate('/')}
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ ease: "easeInOut", duration: 1 }}>
          <div className='inline-flex items-center gap-2 text-sm'>
            <img src="https://keshah.com/wp-content/uploads/2024/09/KESHAHWhiteLogoPNG-2.png" className="w-[80px]" alt="Keshah Logo" />
          </div>
        </motion.div>

        <motion.div className='flex gap-4'
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ ease: "easeInOut", duration: 1 }}>
          <img
            src="https://static.vecteezy.com/system/resources/previews/005/129/844/non_2x/profile-user-icon-isolated-on-white-background-eps10-free-vector.jpg"
            alt="User Profile"
            className="rounded-full w-12 h-12 sm:h-16 sm:w-16 object-cover cursor-pointer"
            onClick={() => setSidebarVisibility(!sidebarVisible)}
          />
          <div className='flex flex-wrap flex-col items-start gap-1 overflow-hidden'>
            <div className='text-white'>{emailVerified ? displayName : 'Hi! User'}</div>
            <div className='text-[10px] text-gray-400'>{email}</div>
            {!emailVerified && (
              <div className='text-[10px] text-red-600 inline-flex items-center gap-2'>
                Account Not Verified
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-3 h-3">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                </svg>
              </div>
            )}
          </div>
        </motion.div>

        <motion.div className='flex flex-col gap-4 overflow-y-auto hide-scrollbar'
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ ease: "easeInOut", duration: 1 }}>
          {dashboardViews.map((item, index) => (
            <div key={index} className='cursor-pointer' onClick={() => handleViewSelect(item)}>
              <div className={`text-xs font-normal w-full px-3 py-2 flex items-center justify-between transition-all ease-in-out duration-500 rounded-lg cursor-pointer ${activeDashboardView.label === item.label ? 'bg-black text-white' : 'bg-[#34343441] text-white'}`}>
                <div className='inline-flex items-center gap-3'>
                  <div className='flex flex-col gap-2'>
                    <span>{item.label}</span>
                    <span className='border-[1px] text-[10px] border-gray-300 px-2 py-1 rounded-md'>
                      Manage Quiz Screens
                    </span>
                  </div>
                </div>
                {index === 0 && (
                  <span className={`text-[10px] px-2 py-1 font-medium rounded-md ${activeDashboardView.label === item.label ? 'bg-white text-black' : 'bg-black text-white'} transition-all ease-in-out duration-500`}>
                    New
                  </span>
                )}
              </div>
            </div>
          ))}
        </motion.div>
      </div>

      {/* Main Content */}
      <div className='grow h-full flex flex-col'>
        <motion.div className='flex items-center justify-between flex-wrap gap-2 px-4 py-4'
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ ease: "easeInOut", duration: 1 }}>
          <div className='inline-flex items-center gap-1'>
            <span className='text-xs font-semibold text-white'>Dashboard</span>
            <span className='text-xs text-blue-500 font-semibold'> / {activeDashboardView.label}</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className='w-4 h-4'>
              <path fillRule="evenodd" d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.28-3.352.75.75 0 0 0-.53-.919Zm-11.623 4.632a.75.75 0 0 0 1.064.055l3.621-3.62a.75.75 0 0 0-1.064-1.064l-3.62 3.621a.75.75 0 0 0-.055 1.064Z" clipRule="evenodd" />
            </svg>
          </div>
          <div className='inline-flex items-center gap-4'>
            <button onClick={handleLogout} className='text-sm text-white border rounded px-2 py-1 hover:bg-gray-200 transition'>
              Sign Out
            </button>
            <div className="flex sm:hidden flex-col gap-1 items-start cursor-pointer" onClick={() => { setSidebarVisibility(!sidebarVisible) }}>
              <div className={`bg-white transition-all ease-in-out duration-200 ${sidebarVisible ? 'w-[22px] h-[2px] transform translate-x-0 translate-y-[5px] rotate-45' : 'w-[22px] h-[2px]'}`}></div>
              <div className={`bg-white transition-all ease-in-out duration-200 ${sidebarVisible ? 'w-0 h-0' : 'w-[16px] h-[2px]'}`}></div>
              <div className={`bg-white transition-all ease-in-out duration-200 ${sidebarVisible ? 'w-[22px] h-[2px] transform translate-x-0 -translate-y-[5px] -rotate-45' : 'w-[10px] h-[2px]'}`}></div>
            </div>
          </div>

        </motion.div >

        <div className='flex-grow overflow-y-auto px-4'>
          {activeDashboardView.component}
        </div>
      </div >
    </div >
  );
}
