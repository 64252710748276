// src/pages/QuizPage.jsx
import React from 'react';
import Quiz from '../../components/user/Quiz';

const QuizPage = () => {
    return (
        <Quiz />
    );
};

export default QuizPage;
