import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';

export default function ThankYou() {
    const animationDelay = 0.5;
    const { sessionID } = useParams();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        // If sessionID is available, send payment details
        const savePaymentDetails = async () => {
            if (!sessionID) {
                setError('Session ID is required.');
                return;
            }

            const dataToSend = { sessionID };

            try {
                setLoading(true); // Set loading state

                // Base64 encode WordPress credentials
                const wpUsername = process.env.REACT_APP_WP_USERNAME;
                const wpPassword = process.env.REACT_APP_WP_APPLICATION_PASSWORD;
                const authHeader = `Basic ${btoa(`${wpUsername}:${wpPassword}`)}`;

                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/handle-successful-payment`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': authHeader,
                    },
                    body: JSON.stringify(dataToSend),
                });

                if (!response.ok) {
                    const errorData = await response.json(); // Get the error response
                    throw new Error(`Failed to save payment details: ${errorData.error || response.statusText}`);
                }

                const data = await response.json();
                console.log('Payment details successfully updated in Firestore:', data);

                // Trigger Facebook Pixel "Subscribe" event
                if (window.fbq) {
                    window.fbq('track', 'Subscribe');
                }
            } catch (error) {
                console.error('Error updating payment details:', error);
                setError(error.message); // Set error state
            } finally {
                setLoading(false); // Reset loading state
            }
        };

        savePaymentDetails();
    }, [sessionID]);

    return (
        <div className='w-full sm:w-[420px] mx-auto min-h-screen'>
            <div className='w-full p-4'>
                <motion.h1 className='mt-6 text-white font-light text-4xl'
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ ease: "easeInOut", duration: 1.5, delay: animationDelay }}>
                    Welcome to the KESHAH Family
                </motion.h1>
                <motion.p className='mt-6 text-white font-light text-sm leading-loose tracking-wide'
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ ease: "easeInOut", duration: 1.5, delay: animationDelay + 0.3 }}>
                    Please check your email for instructions to access the KESHAH App with important Pre-Treatment info. Your Black Kit is on the way and will arrive within 1 to 4 days with complimentary express shipping.
                </motion.p>
                {loading && <motion.p className="mt-6 text-white"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ ease: "easeInOut", duration: 1.5, delay: animationDelay + 0.5 }}>
                    Processing your payment...
                </motion.p>}
                {error && <motion.p className="mt-6 text-red-500"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ ease: "easeInOut", duration: 1.5, delay: animationDelay + 0.5 }}>
                    Error: {error}
                </motion.p>}
            </div>
        </div>
    );
}
