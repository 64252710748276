import React from 'react'

export default function KeshahQuiz() {
  return (
    <div className='relative  w-full h-full rounded-xl shadow-xl bg-[#141414] overflow-hidden'>
      <div className='absolute w-full h-full flex flex-col gap-4 p-4'>

        <div className='w-full flex items-center justify-between'>
          <div className='text-xs text-white font-medium'>Total Screens: 12</div>
          <div className='text-blue-500 text-xs font-medium cursor-pointer inline-flex items-center gap-2 border-2 border-blue-500 px-2 py-1 rounded-lg'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
              <path fillRule="evenodd" d="M4.5 2.25a.75.75 0 0 0 0 1.5v16.5h-.75a.75.75 0 0 0 0 1.5h16.5a.75.75 0 0 0 0-1.5h-.75V3.75a.75.75 0 0 0 0-1.5h-15ZM9 6a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5H9Zm-.75 3.75A.75.75 0 0 1 9 9h1.5a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75ZM9 12a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5H9Zm3.75-5.25A.75.75 0 0 1 13.5 6H15a.75.75 0 0 1 0 1.5h-1.5a.75.75 0 0 1-.75-.75ZM13.5 9a.75.75 0 0 0 0 1.5H15A.75.75 0 0 0 15 9h-1.5Zm-.75 3.75a.75.75 0 0 1 .75-.75H15a.75.75 0 0 1 0 1.5h-1.5a.75.75 0 0 1-.75-.75ZM9 19.5v-2.25a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-.75.75h-4.5A.75.75 0 0 1 9 19.5Z" clipRule="evenodd" />
            </svg>
            Add Screen
          </div>
        </div>

        <div className='w-full h-full overflow-auto border-[0.25px] border-[#555555] rounded-lg'>
          {/* Table */}
          <table className='min-w-max w-full table-auto transition-all ease-in-out duration-300' id='dataTable'>
            <thead className='sticky top-0 z-10'>
              <tr className='text-xs text-white bg-black font-semibold border-[0.25px] border-[#555555]'>
                <th className='text-left py-6 px-2 border-r-[0.25px] border-[#555555]'></th>
                {/* Additional header columns will be dynamically rendered here */}
              </tr>
            </thead>
            <tbody>
              {/* Rows will be dynamically rendered here */}
              <tr className='border-b-[0.25px] border-[#555555] text-xs hover:bg-[#34343441] transition-all ease-in-out duration-500'>
                <td className='text-left py-6 px-2 border-r-[0.25px] border-[#555555]'>

                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  )
}
