import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const Screen = ({ data, onNext, onPrevious, onInputChange, answers, progress }) => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    let delay = 0.3; // Initialize delay in the outer scope for shared use

    useEffect(() => {
        // Scroll to top whenever the data (i.e., screen) changes
        window.scrollTo(0, 0);
    }, [data]);

    useEffect(() => {
        const validateInputs = () => {
            if (!data.questions) return true;

            const requiredQuestions = data.questions.filter(q => q.required);
            for (let question of requiredQuestions) {
                const answer = answers[question.id];
                if (!answer || (Array.isArray(answer) && answer.length === 0)) {
                    return false;
                }
            }
            return true;
        };
        setIsButtonDisabled(!validateInputs());
    }, [answers, data]);

    const renderContent = () => {
        if (!data) return null;

        const elements = [];

        const renderElement = (type, content, key, className = "") => {
            const MotionComponent = motion[type];
            elements.push(
                <MotionComponent
                    key={key}
                    className={className}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ ease: "easeInOut", duration: 0.5, delay }}
                >
                    {content}
                </MotionComponent>
            );
            delay += 0.3; // Increment delay for the next element
        };

        Object.keys(data).forEach((key) => {
            const value = data[key];

            if (key.startsWith('h') && !isNaN(key[1])) {
                const level = key.slice(1);
                const className = `mb-6 text-white font-light text-${level === '1' ? '2xl' : level === '2' ? 'xl' : 'lg'}`;
                renderElement(`h${level}`, value, key, className);
            }

            if (key.startsWith('p') && !isNaN(key[1])) {
                renderElement('p', value, key, "mb-6 text-white font-light");
            }

            if (key.startsWith('img') && !isNaN(key[3])) {
                elements.push(
                    <motion.img
                        key={key}
                        src={value}
                        className="mb-6 w-full h-auto object-contain rounded-xl"
                        alt="Dynamic Content"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ ease: "easeInOut", duration: 1, delay }}
                        onLoad={(e) => {
                            e.target.style.filter = 'none';
                            e.target.style.height = 'auto'; // Transition to the natural height
                        }}
                        style={{
                            filter: "blur(8px)",
                            transition: "filter 1.6s ease-in-out, height 1.6s ease-in-out" // Adding height transition
                        }}
                    />
                );
                delay += 0.3;
            }
        });

        if (data.type === 'quiz' && Array.isArray(data.questions)) {
            data.questions.forEach((q) => {
                renderElement('p', q.question, q.id, `mb-6 text-white font-light ${data.questions.length === 1 ? 'text-2xl' : 'text-sm'}`);
                if (q.description) {
                    renderElement('p', q.description, `${q.id}-desc`, "mb-6 text-white font-extralight text-base");
                }
                elements.push(<div key={`${q.id}-input`}>{renderInput(q)}</div>);
                delay += 0.3;
            });
        }

        // Footer rendering with animation
        if (data.footer) {
            elements.push(
                <motion.div
                    key="footer"
                    className='mt-6 w-full'
                    initial={{ opacity: 0, y: 10 }} // Animation start
                    animate={{ opacity: 1, y: 0 }} // Animation end
                    transition={{ ease: "easeInOut", duration: 0.5, delay: delay }} // Use delay for footer
                >
                    <div className='w-full h-[0.30px] bg-gray-400'></div>
                    <div className='pt-4 text-gray-400 text-xs font-extralight'>
                        {data.footer}
                    </div>
                </motion.div>
            );
        }

        return <>{elements}</>;
    };

    const renderInput = (question) => {
        switch (question.inputType) {
            case 'radio':
            case 'checkbox':
                return (
                    <div className={`grid gap-6 ${question.options.length > 2 ? 'grid-cols-1' : 'grid-cols-2'}`}>
                        {question.options.map((option, optionIndex) => {
                            const optionDelay = delay + optionIndex * 0.2;
                            return (
                                <motion.div
                                    key={optionIndex}
                                    className="h-full flex items-center"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ ease: "easeInOut", duration: 0.5, delay: optionDelay }}
                                >
                                    <input
                                        type={question.inputType}
                                        name={question.id}
                                        id={`option-${optionIndex}-${question.id}`}
                                        value={option}
                                        onChange={() => onInputChange(option, question.id)}
                                        checked={question.inputType === 'checkbox' ? (answers[question.id] || []).includes(option) : answers[question.id] === option}
                                        className="appearance-none hidden"
                                    />
                                    <label htmlFor={`option-${optionIndex}-${question.id}`} className={`font-light text-sm text-white w-full h-full p-4 border rounded-lg cursor-pointer transition-all ease-in-out duration-300 inline-flex items-center justify-between ${(answers[question.id] === option || (question.inputType === 'checkbox' && (answers[question.id] || []).includes(option))) ? 'border-blue-500' : 'border-gray-400 hover:border-gray-50'}`}>
                                        {option}
                                        {question?.recommended === option && <span className='bg-[#369033] p-1 rounded text-xs font-normal'>Recommended</span>}
                                    </label>
                                </motion.div>
                            );
                        })}
                    </div>
                );
            case 'text':
            case 'email':
                return (
                    <motion.input
                        type={question.inputType}
                        placeholder="Your answer"
                        className="mb-6 w-full p-4 border rounded-xl outline-none text-white bg-transparent"
                        value={answers[question.id] || ''}
                        onChange={(e) => onInputChange(e.target.value, question.id)}
                        required={question.inputType === 'email'}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ ease: "easeInOut", duration: 0.5, delay }}
                    />
                );
            case 'textarea':
                return (
                    <motion.textarea
                        placeholder="Your answer"
                        className="mb-6 w-full p-4 border rounded-xl outline-none text-white h-24 bg-transparent"
                        value={answers[question.id] || ''}
                        onChange={(e) => onInputChange(e.target.value, question.id)}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ ease: "easeInOut", duration: 0.5, delay }}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className='w-full sm:w-[420px] mx-auto min-h-screen'>
            <div className='w-full p-4'>
                <motion.div className="relative w-full h-[2px] bg-gray-200 rounded-full overflow-hidden" initial={{ width: 0 }} animate={{ width: `${progress}%` }} transition={{ ease: 'easeInOut', duration: 0.5 }}></motion.div>

                <motion.div className='mt-6 w-full h-fit flex items-center justify-between' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ ease: "easeInOut", duration: 0.5, delay: 0.3 }}>
                    <svg onClick={onPrevious} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5 text-white cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                    </svg>
                    <img src="https://keshah.com/wp-content/uploads/2024/09/KESHAHWhiteLogoPNG-2.png" className="h-[24px] w-auto" alt="Keshah Logo" />
                    <div className='opacity-0'>ass</div>
                </motion.div>

                <motion.div className='mt-6 w-full'>{renderContent()}</motion.div>
            </div>

            <motion.div className='w-full p-4' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ ease: "easeInOut", duration: 1, delay: delay + 0.5 }}>
                <button onClick={onNext} disabled={isButtonDisabled} className={`text-black flex h-12 w-full items-center justify-center rounded-xl text-base font-semibold lg:text-base ${isButtonDisabled ? 'cursor-not-allowed bg-gray-300' : 'bg-white cursor-pointer'}`}>
                    <span className="tracking-tight bg-transparent">Next</span>
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-2">
                        <path d="M6.1584 3.13508C6.35985 2.94621 6.67627 2.95642 6.86514 3.15788L10.6151 7.15788C10.7954 7.3502 10.7954 7.64949 10.6151 7.84182L6.86514 11.8418C6.67627 12.0433 6.35985 12.0535 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1579L9.565 7.49985L6.1356 3.84182C5.94673 3.64036 5.95694 3.32394 6.1584 3.13508Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
                    </svg>
                </button>
            </motion.div>
        </div>
    );
};

export default Screen;
