import React, { useState, useEffect, useCallback } from 'react';
import Screen from './Screen';
import quizData from '../../data/quizData';
import { motion } from 'framer-motion';
import QuizCompleted from './QuizCompleted';
import WalkthroughScreen from './WalkthroughScreen';

const Quiz = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [answers, setAnswers] = useState({});
    const [quizCompleted, setQuizCompleted] = useState(false);
    const [isExiting, setIsExiting] = useState(false);
    const [postQuizStep, setPostQuizStep] = useState(0);

    const handlePrevious = () => {
        if (quizCompleted) {
            if (postQuizStep > 0) {
                setPostQuizStep(prevStep => prevStep - 1);
            } else {
                setIsExiting(true);
                setTimeout(() => {
                    setCurrentIndex(quizData.length - 1);
                    setQuizCompleted(false);
                    setIsExiting(false);
                }, 300);
            }
        } else if (currentIndex > 0) {
            setIsExiting(true);
            setTimeout(() => {
                setCurrentIndex(prevIndex => prevIndex - 1);
                setIsExiting(false);
            }, 300);
        }
    };

    const postQuizScreens = [
        <WalkthroughScreen onNext={() => setPostQuizStep(1)} onPrevious={() => handlePrevious()} quizCompleted={quizCompleted} answers={answers} />,
        <QuizCompleted answers={answers} onPrevious={handlePrevious} />
    ];

    const handleNext = useCallback(() => {
        if (quizCompleted) {
            if (postQuizStep < postQuizScreens.length - 1) {
                setPostQuizStep(prevStep => prevStep + 1);
            }
        } else {
            if (currentIndex < quizData.length - 1) {
                setIsExiting(true);
                setTimeout(() => {
                    setCurrentIndex(prevIndex => prevIndex + 1);
                    setIsExiting(false);
                }, 300);
            } else {
                setQuizCompleted(true);
            }
        }
    }, [currentIndex, quizCompleted, postQuizStep, postQuizScreens.length]);

    const handleInputChange = (value, questionId) => {
        // Ensure the current index is valid
        if (currentIndex >= 0 && currentIndex < quizData.length) {
            const currentQuiz = quizData[currentIndex]; // Get the current quiz
            const questionObj = currentQuiz.questions?.find(q => q.id === questionId); // Find the question by ID

            // If question not found, log an error
            if (!questionObj) {
                return console.error("Question not found for the provided questionId:", questionId);
            }

            const { inputType } = questionObj; // Get the input type of the question
            setAnswers(prevAnswers => {
                const newAnswers = { ...prevAnswers }; // Create a copy of previous answers

                // Handling different input types
                if (inputType === 'checkbox') {
                    const currentAnswers = newAnswers[questionId] || []; // Get current answers for the checkbox
                    newAnswers[questionId] = currentAnswers.includes(value)
                        ? currentAnswers.filter(answer => answer !== value) // Remove value if already present
                        : [...currentAnswers, value]; // Add value if not present
                } else if (inputType === 'radio' || inputType === 'text' || inputType === 'email' || inputType === 'textarea') {
                    newAnswers[questionId] = value; // Set the value directly for these input types
                }

                return newAnswers; // Return the updated answers
            });
        }
    };

    useEffect(() => {
        if (quizData[currentIndex].type === 'info') {
            const timer = setTimeout(() => {
                handleNext();
            }, quizData[currentIndex].duration || 3000);

            return () => clearTimeout(timer);
        }
    }, [currentIndex, handleNext]);

    if (quizCompleted) {
        return (
            <motion.div
                key="post-quiz-screen"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1 }}
            >
                {postQuizScreens[postQuizStep]}
            </motion.div>
        );
    }

    const progress = ((currentIndex + 1) / quizData.length) * 100;

    return (
        <motion.div
            key={currentIndex}
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            animate={{ opacity: isExiting ? 0 : 1 }}
            transition={{ duration: 0.3 }}
        >
            <Screen
                data={quizData[currentIndex]}
                onNext={handleNext}
                onPrevious={handlePrevious}
                onInputChange={handleInputChange}
                answers={answers}
                progress={progress}
            />
        </motion.div>
    );
};

export default Quiz;
