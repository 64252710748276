import React from 'react'

export default function Footer() {
    return (
        <div className='w-full absolute bottom-0'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ffffff" fillOpacity="1" d="M0,224L21.8,192C43.6,160,87,96,131,96C174.5,96,218,160,262,192C305.5,224,349,224,393,218.7C436.4,213,480,203,524,197.3C567.3,192,611,192,655,170.7C698.2,149,742,107,785,80C829.1,53,873,43,916,53.3C960,64,1004,96,1047,138.7C1090.9,181,1135,235,1178,218.7C1221.8,203,1265,117,1309,74.7C1352.7,32,1396,32,1418,32L1440,32L1440,320L1418.2,320C1396.4,320,1353,320,1309,320C1265.5,320,1222,320,1178,320C1134.5,320,1091,320,1047,320C1003.6,320,960,320,916,320C872.7,320,829,320,785,320C741.8,320,698,320,655,320C610.9,320,567,320,524,320C480,320,436,320,393,320C349.1,320,305,320,262,320C218.2,320,175,320,131,320C87.3,320,44,320,22,320L0,320Z"></path></svg>
            <div className='bg-white'>
                <section className="pb-24 container mx-auto px-4 flex flex-col gap-6 items-center">
                    <h2 className="text-black text-2xl xl:text-4xl font-extrabold text-center bg-transparent">
                        Having trouble? <br /> Contact Us
                    </h2>

                    <div className='flex flex-wrap items-center justify-center gap-4 max-w-xl bg-transparent'>
                        <a href="tel:8334384894" rel="noreferrer" className='px-6 py-2 bg-black text-white rounded-3xl inline-flex items-center gap-2 cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                                <path fillRule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clipRule="evenodd" />
                            </svg>
                            Call
                        </a>

                        <a href="mailto:support@gu-wi.com" rel="noreferrer" className='px-6 py-2 bg-black text-white rounded-3xl inline-flex items-center gap-2 cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                                <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                                <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                            </svg>
                            Email Us
                        </a>

                        <a href="https://www.upwork.com/freelancers/jatindahiya" target='_blank' rel="noreferrer" className='px-6 py-2 bg-black text-white rounded-3xl inline-flex items-center gap-2 cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                                <path fillRule="evenodd" d="M2.25 6a3 3 0 0 1 3-3h13.5a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V6Zm3.97.97a.75.75 0 0 1 1.06 0l2.25 2.25a.75.75 0 0 1 0 1.06l-2.25 2.25a.75.75 0 0 1-1.06-1.06l1.72-1.72-1.72-1.72a.75.75 0 0 1 0-1.06Zm4.28 4.28a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Z" clipRule="evenodd" />
                            </svg>
                            Developed By Jatin Dahiya
                        </a>

                        <a href="https://www.thewebnavigators.com/" target='_blank' rel="noreferrer" className='px-6 py-2 bg-black text-white rounded-3xl inline-flex items-center gap-2 cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                                <path fillRule="evenodd" d="M4.5 2.25a.75.75 0 0 0 0 1.5v16.5h-.75a.75.75 0 0 0 0 1.5h16.5a.75.75 0 0 0 0-1.5h-.75V3.75a.75.75 0 0 0 0-1.5h-15ZM9 6a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5H9Zm-.75 3.75A.75.75 0 0 1 9 9h1.5a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75ZM9 12a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5H9Zm3.75-5.25A.75.75 0 0 1 13.5 6H15a.75.75 0 0 1 0 1.5h-1.5a.75.75 0 0 1-.75-.75ZM13.5 9a.75.75 0 0 0 0 1.5H15A.75.75 0 0 0 15 9h-1.5Zm-.75 3.75a.75.75 0 0 1 .75-.75H15a.75.75 0 0 1 0 1.5h-1.5a.75.75 0 0 1-.75-.75ZM9 19.5v-2.25a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-.75.75h-4.5A.75.75 0 0 1 9 19.5Z" clipRule="evenodd" />
                            </svg>
                            Engineered By The Web Navigators
                        </a>

                    </div>

                    <p className='text-xs xl:text-sm text-black max-w-xs xl:max-w-lg text-center bg-transparent'>
                        © {new Date().getFullYear()} All Rights Reserved
                    </p>
                </section>
            </div>
        </div>
    )
}
